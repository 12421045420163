<template>
  <div v-if="Object.keys(content).length" class="cta">
    <div class="cta__trigger ">
      <div class="cta__trigger-content" v-if="!decrease">
        <ButtonComponent
          class="cta__trigger-decrease"
          :icon="['fal', 'times']"
          @click.stop="decrease = true"
        />
        <div class="cta__trigger-label" v-html="content"></div>
      </div>
      <ButtonComponent
        v-else
        class="cta__trigger-increase"
        :icon="['far', 'arrow-right']"
        @click.stop="decrease = false"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { ModalComponent as Modal } from 'vue-elder-modal'
import { GetCustomField, ScrollToAnchor } from '@/utils'

export default {
  data() {
    return {
      decrease: false,
    }
  },
  computed: {
    ...mapState('Project', ['item']),

    content() {
      if (!this.item) return {}
      return GetCustomField('cta', this.item.customFields) || {}
    },
  },
  methods: {
    navigate() {
      ScrollToAnchor('lead')
    },
  },
  components: {
    Modal,
  },
}
</script>

<style lang="scss">
.cta {
  &__trigger {
    box-shadow: 10px 10px 15px -10px rgba(black, 0.4);
    $padding-y: 1rem;
    $padding-x: 1rem;
    position: fixed;
    left: 0;
    top: 70%;
    background-color: var(--secondary);
    color: var(--primary);

    display: flex;

    text-decoration: none;
    border-radius: 0 0 $border-radius $border-radius;
    z-index: get-layer('cta');
    white-space: nowrap;
    flex-direction: column;
    gap: 1rem;

    @include respond-below('phone') {
      top: 20%;
      justify-content: center;
      align-items: center;
    }

    &-content {
      padding: $padding-y $padding-x;
      display: flex;

      gap: 1.5rem;
      max-width: 300px;

      @include respond-below('phone') {
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;
        gap: 0.5rem;
        max-width: 200px;
        .elder-button {
          font-size: 0.9em;
        }
      }
    }

    &-label {
      white-space: break-spaces;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      font-family: var(--secondary-font);
      font-size: 1.25em;

      @include respond-below('phone') {
        text-align: center;
        font-size: 0.7em;
      }

      p {
        margin: 0;
      }
    }
    &-decrease {
      position: absolute !important;
      right: 0;
      top: 0;
      border: none !important;
      .elder-button {
        &__icon {
          padding: 0.5rem !important;
          svg {
            font-size: 1rem;
          }
        }
      }
    }
    &-increase {
      background-color: var(--secondary) !important;
      border: none !important;
      min-height: initial;

      .elder-button {
        &__icon {
          display: flex;

          &:first-child:last-child {
            padding: 1rem;
          }
          svg {
            font-size: 1.3rem;
            @include respond-below('phone') {
              font-size: 0.9rem;
            }
          }
        }
      }
    }
  }
}
</style>
