<template>
  <div class="summary">
    <div class="summary__content" v-if="properties.length">
      <div
        class="summary__property"
        v-for="(property, index) in properties"
        :key="property.key + index"
      >
        <div>
          <div class="summary__property-label">{{ property.label || $tc(property.key) }}</div>
          <div class="summary__property-value">{{ property.formatted }}</div>
        </div>
      </div>
    </div>
    <div class="summary__price-wrapper">
      <div class="summary__price">
        <div v-if="hasPrice && data.status === 'sale'" class="summary__price-label">
          <div v-if="$path('sale.jointDebt', data)">
            <template>+ {{ $t('jointDebt').toLowerCase() }}</template>
            <template v-if="jointDebt"> ({{ jointDebt | Currency }})</template>
          </div>
          <template v-if="$path('sale.fee.included', data)">{{
            $t('residentialSaleFeeIncluded', { value: null })
          }}</template>
          <template v-else> + {{ $t('residentialSaleFee').toLowerCase() }} </template>
          <template v-if="fee">({{ fee | Currency }})</template>
        </div>
        <h2 class="summary__price-value">{{ price }}</h2>
      </div>
    </div>
    <div class="summary__bottom">
      <BrochureComponent :data="brochure" />
      <ButtonComponent
        v-if="bidUrl && data.status === 'sale'"
        theme="primary"
        label="Kjøp nå"
        tag="a"
        :href="`//${bidUrl}`"
        target="_blank"
      />

      <ButtonComponent
        label="Kontakt megler"
        type="button"
        theme="secondary"
        @click="ScrollToAnchor('lead')"
      />
    </div>
  </div>
</template>

<script>
import { Currency } from '@/filters'
import { ScrollToAnchor } from '@/utils'
import BrochureComponent from '@/components/Custom/Brochure'

const mapLabel = {
  primaryRoomArea: 'P-rom',
  useableArea: 'BRA',
}

export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    brochure() {
      return this.$path('data.project.media.brochure') || []
    },
    bidUrl() {
      return this.$path('data.sale.bidUrl')
    },
    hasPrice() {
      return Boolean(this.$path('data.sale.value'))
    },
    price() {
      if (!this.data.status) return
      let price = Currency(this.$path('data.sale.value'))
      switch (this.data.status) {
        case 'sale':
          if ([null, undefined].includes(price)) return this.$t('priceOnRequest')
          return price
        case 'reserved':
          return `${this.$t('residentialStatus.' + this.data.status)}${
            price && !KvassConfig.get('siteSettings.hideReservedPrice') ? ` (${price})` : ''
          }`
        default:
          return this.$t('residentialStatus.' + this.data.status)
      }
    },
    fee() {
      return this.$path('data.sale.fee.value')
    },
    jointDebt() {
      return this.$path('data.sale.jointDebt')
    },
    properties() {
      if (!this.data.properties) return []
      return (this.$path('data.properties') || [])
        .filter(p => p.formatted)
        .filter(i => !['propertyType', 'numberOfFloors'].includes(i.key))
        .map(i => {
          return {
            ...i,
            label: mapLabel[i.key] || i.label,
            formatted: i.formatted.replace('m²', 'kvm'),
          }
        })
    },
  },
  methods: {
    ScrollToAnchor,
  },
  filters: {
    Currency,
  },
  components: {
    BrochureComponent,
  },
}
</script>

<style lang="scss">
.summary {
  color: var(--body-color);
  background: transparent;

  display: flex;
  flex-direction: column;

  &__content {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    flex-grow: 1;
    gap: 1rem;
    max-height: 400px;
    overflow-y: auto;
  }

  & > div > * > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__address {
    font-size: 1.2em;
    display: flex;
    flex-direction: row !important;

    &-dash {
      opacity: 0.3;
      margin: 0 0.5em;
    }
  }

  &__title {
    margin-bottom: 0rem;
    @include respond-below('tablet') {
      margin-left: 0.5em;
    }
  }

  &__price {
    margin-top: 1rem;
    flex-direction: column;

    @include respond-below('tablet') {
      margin-left: 0.5em;
    }

    h2 {
      margin-top: 0;
    }
    &-value {
      font-weight: bold;
      font-size: 2em;
    }

    &-label {
      opacity: 0.75;
      font-size: 1em;
      opacity: 0.75;
      display: flex;
      gap: 0.5rem;
      flex-wrap: wrap;
      flex-direction: row !important;
      justify-content: start !important;
      @include respond-below('tablet') {
        font-size: 0.9em;
      }
    }
  }

  &__property {
    padding: 0.5em;
    @include respond-below('tablet') {
      padding: 1em;
    }
    &-label {
      text-transform: uppercase;
      font-size: 0.7em;
      opacity: 0.75;
    }

    &-value {
      font-weight: bold;
      font-size: 1.2em;
    }
  }

  &__bottom {
    gap: 1em;
    display: flex;
    flex-direction: column;
    text-align: center;
  }
}
</style>
