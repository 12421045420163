function getValueFromArray(array = [], value, match) {
  return array.find(i => i[value] === match) || {}
}

export default function() {
  const get = path => this.$path(path, this.$store.state)

  const stats = get('Root.item.stats') || {}

  const showStats = () => {
    const { sale, sold, rent, rented, reserved, upcoming, total } = stats
    const published = sale + sold + reserved + rent + rented

    return total === 0 || (upcoming && !published)
  }

  const statsContent = () => {
    const { upcoming } = stats
    const saleType = get('Root.item.metadata.residentialProperties.saleType') || []

    return upcoming
      ? this.$t(`residentialStatus:${saleType.includes('rent') ? 'rent' : 'sale'}.upcoming`)
      : this.$t('upcomingLabel')
  }

  return [
    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-hero',
        class: 'section-hero',
        style: 'padding:0;',
      },
      blocks: {
        default: [
          {
            component: 'Slider',
            props: {
              class: 'hero',
              aspectRatio: '16/9',
              class: 'hero__slider',
              options: {
                manualNavigation: false,
              },
            },
            data: get('Project.item.media.cover'),

            blocks: {
              default: {
                component: 'Flex',
                props: {
                  class: `hero__text hero__text-placement--${get(
                    'Project.item.customFields.project-hero.placement',
                  ) || 'center'}`,
                },
                blocks: {
                  default: [
                    {
                      component: 'Text',
                      props: {
                        textAlign: 'center',
                      },
                      data: {
                        content: `<h1>${get('Project.item.customFields.project-hero.title') ||
                          ''}</h1> <p>${get('Project.item.customFields.project-hero.content') ||
                          ''}</p>`,
                      },
                    },
                    {
                      component: 'Flex',
                      props: {
                        alignItems: 'center',
                      },
                      blocks: {
                        default: [
                          {
                            component: 'Text',
                            data: {
                              content: (get('Project.item.customFields.project-hero-buttons') || [])
                                .filter(button => {
                                  if (button?.type === 'brochure') return
                                  return Boolean(button.content)
                                })
                                .map(
                                  button =>
                                    `<a target="_self" data-cta="${
                                      button.type === 'flatfinder' ? 'secondary' : 'primary'
                                    }" href="#${button.type}">${button.content}</a>`,
                                )
                                .join(''),
                            },
                          },
                          {
                            component: 'Brochure',
                            condition:
                              (get('Project.item.media.brochure') || []).length &&
                              getValueFromArray(
                                get('Project.item.customFields.project-hero-buttons'),
                                'type',
                                'brochure',
                              ).type,
                            data: get('Project.item.media.brochure'),
                            props: {
                              style: 'padding-top: auto;',
                              placement: 'top',
                              label: getValueFromArray(
                                get('Project.item.customFields.project-hero-buttons'),
                                'type',
                                'brochure',
                              ).content,
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              },
              bottom: showStats()
                ? {
                    component: 'Text',
                    props: {
                      class: 'hero__stats',
                    },
                    data: {
                      content: statsContent(),
                    },
                  }
                : {},
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'small',
        id: 'section-info',
        class: 'section-info',
        style: 'padding-bottom:0;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'section-info',
          },

          {
            component: 'Flex',
            props: {
              flexDirection: 'column',
              class: 'section-info__content',
              style: 'opacity: 0; transform: translateX(-5rem);',
            },

            blocks: {
              default: [
                {
                  component: 'Text',
                  props: {
                    'text-align': 'center',
                    class: 'project__description',
                    style: 'font-size: 1.25rem;',
                  },

                  data: {
                    content: get('Project.item.description'),
                  },
                },
                {
                  condition: (get('Project.item.media.brochure') || []).length,

                  component: 'Brochure',
                  data: get('Project.item.media.brochure'),
                  props: {
                    style: 'padding-top: auto;',
                  },
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.customFields.additional-content.image.url'),
      props: {
        width: 'medium',
        id: 'section-info-image',
        class: 'section-info-image',
        style: 'opacity: 0;',
      },
      blocks: {
        default: [
          {
            component: 'Image',
            data: {
              image: get('Project.item.customFields.additional-content.image'),
            },
            props: {
              aspectRatio: '16/9',
              class: [
                {
                  'info-image--frame': (
                    get('Project.item.customFields.additional-content.settings') || []
                  ).includes('frame'),
                },

                {
                  'info-image--round-edges': (
                    get('Project.item.customFields.additional-content.settings') || []
                  ).includes('round-edges'),
                },
              ],
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.customFields.section.0.image.url'),
      props: {
        id: 'section-image-text',
        class: 'section-image-text',
      },
      blocks: {
        default: [
          {
            component: 'ImageText',
            data: {
              content: get('Project.item.customFields.section'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Root.item.flatfinders.length') && get('Root.item.stats.total'),
      props: {
        width: 'medium',
        id: 'section-flatfinder',
        class: 'section-flatfinder',
        style: 'padding-bottom:1rem',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'flatfinder',
          },
          {
            component: 'Flatfinder',
            data: {
              properties: get('Project.item.metadata.siteSettings.featuredResidentialProperties'),
              flatfinders: get('Project.item.flatfinders'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Root.item.stats.total'),
      props: {
        width: 'medium',
        id: 'section-residentials',
        class: 'section-residentials',
        style: 'padding-top:1rem',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'residentials',
          },
          {
            component: 'Residentials',
            data: {
              project: get('Project.item.id'),
            },
            props: {
              title: get('Project.item.customFields.title-overrides.residentials'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.customFields.residential-templates.references.length'),
      props: {
        width: 'medium',
        id: 'section-residential-templates',
        class: 'section-residential-templates',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'residential-templates',
          },
          {
            component: 'ResidentialTemplates',
            data: {
              content: get('Project.item.customFields.residential-templates'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      condition: get('Project.item.media.attachments.length'),
      props: {
        width: 'medium',
        id: 'section-attachments',
        class: 'section-attachments',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'attachments',
          },
          {
            component: 'Attachments',
            data: get('Project.item.media.attachments'),
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'full',
        id: 'section-map',
        class: 'section-map',
        style: 'padding-bottom:0;',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'map',
          },
          {
            component: 'Map',
            props: {
              aspectRatio: '21/9',
            },
            data: {
              ...get('Project.item.address'),
              coordinates: get('Project.item.address.location.coordinates'),
            },
          },
        ],
      },
    },

    {
      component: 'Section',
      props: {
        width: 'medium',
        id: 'section-contact',
        class: 'section-contact',
        style: 'background-color: var(--light-grey);',
      },
      blocks: {
        default: [
          {
            component: 'ScrollAnchor',
            data: 'lead',
          },
          {
            component: 'Grid',
            props: {
              gap: '2rem 8rem',
              columns: '1:2',
            },
            blocks: {
              default: [
                {
                  component: 'Flex',
                  props: {
                    flexDirection: 'column',
                    gap: '2rem',
                  },
                  blocks: {
                    default: [
                      {
                        component: 'Openhouse',
                        props: {
                          class: 'project-openhouse',
                        },
                        data: {
                          content: get('Project.item.openhouses'),
                        },
                        condition: () => {
                          if (!this.item) return false
                          if (get('Project.item.openhouses.length')) return true
                          return !get('Root.item.metadata.siteSettings.hideNoOpenhouse')
                        },
                      },
                      {
                        component: 'Roles',
                        data: {
                          content: get('Project.item.roles.salesman'),
                        },
                        props: {
                          class: 'contact-page__salesman',
                          showLogo: false,
                        },
                      },
                    ],
                  },
                },

                {
                  component: 'Lead',
                  props: {
                    options: {
                      placeholders: {
                        'contact.name': this.$t('name'),
                        'contact.email': this.$t('email'),
                        'contact.phone': this.$t('phone'),
                        comment: this.$tc('message', 1),
                      },
                      fields: ['!title', '!contact.upsell'],
                      actionLabel: this.$t('send'),
                    },
                  },
                  data: {
                    project: get('Project.item.id'),
                    reference: { onModel: 'Project', ref: get('Project.item.id') },
                  },
                  blocks: {
                    top: [
                      {
                        component: 'Text',
                        data: {
                          content: get('Project.item.customFields.lead-top-content'),
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ]
}
