import Vue from 'vue'
import VueI18n from 'vue-i18n'
const messages = require('../i18n/nb.js')

import moment from 'moment'
import 'moment/locale/nb'
moment.locale('nb')

Vue.use(VueI18n)
const defaultLanguage = 'nb'

export const i18n = new VueI18n({
  locale: defaultLanguage,
  fallbackLocale: defaultLanguage,
  messages: {
    [defaultLanguage]: messages,
  },
})

const loadedLanguages = [defaultLanguage]

function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return import(/* webpackChunkName: "lang-[request]" */ `../i18n/${lang}`).then(msgs => {
        i18n.setLocaleMessage(lang, msgs)
        loadedLanguages.push(lang)
        return setI18nLanguage(lang)
      })
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  return Promise.resolve(lang)
}

export function mapi18n(key, value) {
  function resolveEntry(entry) {
    let key, args

    switch (entry.constructor) {
      case String:
        key = args = entry
        break
      case Array:
        ;[key, args] = entry
        break
      case Object:
        ;[key, args] = Object.entries(entry)[0]
    }

    switch (args.constructor) {
      case Function:
        return [key, args.call(this)]
      case String:
        return [key, i18n.tc(args)]
      case Array:
        return [key, i18n.tc(...args)]
      default:
        return [key, i18n.tc(key, args)]
    }
  }

  return {
    [key]() {
      let base = value instanceof Array ? value : Object.entries(value)
      return Object.fromEntries(base.map(resolveEntry.bind(this)))
    },
  }
}
