<template>
  <Loader :value="promises.fetch" theme="default">
    <div class="project-page">
      <Blocks ref="blocks" :blocks="config" :custom-components="customComponents" />
    </div>
  </Loader>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ProjectComponents from '@/components/Project'
import CustomComponents from '@/components/Custom'

import BrowserApiMixin from '@/mixins/browser-api'
import { GetCustomField } from '@/utils'
import { Blocks } from '@kvass/pagebuilder'
import Config from '../config/project'
import { animate, inView, stagger } from 'motion'

export default {
  mixins: [BrowserApiMixin('project')],

  computed: {
    ...mapState('Project', ['promises', 'item']),
    config() {
      return Config.call(this)
    },
    customComponents() {
      return {
        ...CustomComponents,
        ...ProjectComponents,
      }
    },
  },

  methods: {
    ...mapActions('Project', ['fetch']),
    GetCustomField,
    async initAnimation() {
      animate(
        '.hero__text ',
        { opacity: 1, transform: 'none' },
        { duration: 0.8, delay: stagger(0.3) },
      )

      inView('.section-info-image', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })

      inView(
        '.section-info__content',
        ({ target }) => {
          animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
        },
        { margin: '0px 0px -200px 0px' },
      )

      inView('.kvass-image-text__item', ({ target }) => {
        animate(target, { opacity: 1, transform: 'none' }, { duration: 0.8 })
      })
    },
  },
  created() {
    this.fetch()
  },
  mounted() {
    this.promises.fetch.then(() => this.$nextTick(() => this.initAnimation()))
  },

  metaInfo() {
    return {
      title: this.$t('home'),
    }
  },

  components: {
    ...ProjectComponents,
    Blocks,
  },
}
</script>

<style lang="scss">
.nav--project-expanded + .elder-loader {
  .cta-banner {
    display: none;
  }
}
.project-page {
  @for $i from 1 through 15 {
    & > *:nth-child(#{$i}) {
      order: $i * 10;
    }
  }
  .hero {
    &__slider {
      $height: 100vh;

      position: relative;
      height: $height;
      width: 100%;
      background-color: css-alpha('primary', 0.1);
      img {
        object-position: center;
      }
      iframe {
        min-height: 100vh;
      }

      display: flex;
      flex-direction: column;
      @include respond-below('phone') {
        iframe {
          min-height: 100vh;
        }
      }
    }

    &__stats {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      translate: -50%;
      font-size: 1.2em;
      background-color: css-alpha('primary-contrast', 50%);
      backdrop-filter: blur(8px);
      padding: 1em 2em;
    }
  }

  .hero__text {
    opacity: 0;
    top: 0;
    left: 0;
    position: absolute;
    bottom: initial;
    right: initial;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    color: white;
    font-size: clamp(1rem, 1.4rem, 2rem);
    background-color: rgba(black, 0.3);

    &-placement {
      &--center {
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      &--left {
        align-items: flex-start;
        justify-content: center;

        .kpb-text {
          text-align: left;
          margin-left: 3rem;
          @include respond-below('phone') {
            margin-left: 1rem;
          }
        }
      }
    }
  }

  .hero__text {
    & > * {
      padding: 0rem 1rem;
    }

    h1 {
      margin-bottom: clamp(1rem, 2rem, 3rem);
      font-size: clamp(1rem, 11vw, 4rem);
      margin-bottom: 1rem;
    }
    p {
      margin-top: 0;
    }

    .elder-button__label {
      font-size: 1.1rem;
      padding: 0.9rem 1.25rem;
      @include respond-below('phone') {
        padding: 0.5rem 1rem;

        font-size: 0.8rem;
      }
    }
    .elder-button--icon-right .elder-button__icon {
      padding: 0.9rem 1.25rem 0.9rem 0;
      font-size: 1.1rem;
      @include respond-below('phone') {
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
      }
    }
    a {
      margin: 0 0.5rem;
      &[data-cta='primary'] {
        background: white;
        color: black;
        font-weight: 500;
        &:hover {
          background: rgba(white, 0.9);
        }
      }
      &[data-cta='secondary'] {
        font-weight: 500;
        background: transparent;
        color: white;
        border: 1px solid white;
        &:hover {
          background: rgba(black, 0.2);
        }
      }
    }
  }

  .section-residentials {
    .kpb-section__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }

  .section-info-image,
  .section-info {
    @include respond-below('phone') {
      padding: 2rem 1rem;
    }
    &__container {
      @include respond-below('phone') {
        padding: 0;
      }
    }
  }

  .kpb-blocks {
    .section-info {
      margin-bottom: 1em;

      &__content {
        align-items: center;
      }
    }
    .section-info-image {
      .kpb-section__container {
        @include respond-below('phone') {
          padding: 0;
        }
      }
      .info-image {
        &--frame {
          img {
            border: 3px solid var(--primary);
            padding: 0.4rem;
            background: transparent;
          }
        }
        &--round-edges {
          $radius: 3rem;
          img {
            border-radius: $radius;
          }

          .kvass-media-render-image__caption {
            border-radius: $radius;
          }

          @include respond-below('phone') {
            img {
              border-radius: calc(#{$radius} - 1rem);
            }
            .kvass-media-render-image__caption {
              border-radius: calc(#{$radius} - 1rem);
            }
          }
        }
      }
    }
  }
}
</style>
