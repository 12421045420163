import { path as RPath } from 'ramda'

const Clone = function() {
  return JSON.parse(JSON.stringify.apply(this, arguments))
}

function ScrollIntoView(selector, offset) {
  let element = document.querySelector(selector)
  if (!element) return

  let elementPosition = element.getBoundingClientRect().top
  let offsetPosition = window.pageYOffset + elementPosition + offset

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  })
}

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

function getDimensions(url) {
  return new Promise((resolve, reject) => {
    if (!url) return reject(new Error('Url is required'))
    let img = new Image()
    img.onload = function() {
      resolve({
        width: img.width,
        height: img.height,
      })
    }
    img.onerror = reject
    img.src = url
  })
}

function RandomBetween(min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

function MakeArray(val) {
  if ([undefined, null].includes(val)) return []
  return val instanceof Array ? val : [val]
}

function Loop(items, cb, options = {}) {
  let { immediate = true, timing = 4000 } = options
  let current, interval, core

  let start = () => {
    stop()
    interval = setInterval(next, timing)
    next()
    return core
  }
  let stop = () => {
    if (!interval) return
    clearInterval(interval)
    return core
  }
  let reset = () => {
    goTo(0)
    return core
  }
  let next = () => {
    current = items[getIndex(1)]
    cb(current)
    return core
  }
  let prev = () => {
    current = items[getIndex(-1)]
    cb(current)
    return core
  }
  let goTo = index => {
    current = items[index]
    cb(current)
    return core
  }
  let getIndex = delta => {
    delta = delta % items.length
    let currentIndex = items.indexOf(current)
    let targetIndex = currentIndex + delta

    if (targetIndex > items.length - 1) return targetIndex - items.length
    if (targetIndex < 0) return items.length - targetIndex
    return targetIndex
  }

  core = {
    start,
    stop,
    reset,
    next,
    prev,
    goTo,
    getIndex,
    destroy: stop,
  }

  if (items.length === 1) {
    cb(items[0])
    return core
  }
  if (immediate) start()

  return core
}

function GetRouteBasedValue(config = {}) {
  return function() {
    let selector = MakeArray(config[this.$route.name] || config['Project'])
    let value = selector
      .map(s => {
        if (typeof s === 'function') return s.call(this)
        return this.$path(s, this.$store.state)
      })
      .find(Boolean)

    return value || config.default
  }
}

function removeEmptyHTML(item) {
  //pattern for empty html tag
  const pattern = new RegExp('(((<\\w+>)+[ \n(<br>)]*(<\\/\\w+>)+)+)|<br>', 'g')

  if (item instanceof Array) {
    return item.filter(i => {
      let result = typeof i === 'string' ? i.replace(pattern, '') : i
      if (!result) return
      return result
    })
  }

  return Object.fromEntries(
    Object.entries(item).filter(([key, value]) => {
      let result = typeof value === 'string' ? value.replace(pattern, '') : value
      if (!result) return
      return {
        [key]: result,
      }
    }),
  )
}

function GetCustomField(key, obj = {}, path = '') {
  return RPath([key, ...(path ? path.split('.') : [])], obj)
}

function SplitHtml(value, pattern = '<h2>') {
  if (!value) return []
  let splitter = '<<SPLITTER>>'
  let regex = new RegExp(pattern, 'g')
  return value
    .replace(regex, splitter + pattern)
    .split(splitter)
    .filter(Boolean)
}

function DownloadUrl(url, name) {
  let link = document.createElement('a')
  link.setAttribute('target', '_blank')
  link.href = url
  document.body.appendChild(link)
  link.click()
  link.remove()
}

function Capitalize(value) {
  return value.charAt(0).toUpperCase() + value.substring(1)
}
function ScrollToAnchor(value) {
  let target = document.querySelector('.scroll-anchor--type-anchor.scroll-anchor--value-' + value)
  if (!target) return
  target.scrollIntoView({ behavior: 'smooth' })
}

function Debounce(fn) {
  let frame
  return (...params) => {
    if (frame) cancelAnimationFrame(frame)
    frame = requestAnimationFrame(() => fn(...params))
  }
}

function LoadFacebookPixel(id) {
  let script = document.createElement('script')
  script.innerHTML = `!function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', ${id});
  fbq('track', 'PageView');`

  let noscript = document.createElement('noscript')
  noscript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1"/>`

  const elements = [script, noscript]
  elements.forEach(element => document.body.appendChild(element))
}

function DarkTheme(color) {
  if (!color) return
  const hex = color.replace('#', '')
  const c_r = parseInt(hex.substring(0, 0 + 2), 16)
  const c_g = parseInt(hex.substring(2, 2 + 2), 16)
  const c_b = parseInt(hex.substring(4, 4 + 2), 16)
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
  return brightness < 155
}

export {
  DownloadUrl,
  Clone,
  getCookie,
  getDimensions,
  RandomBetween,
  MakeArray,
  Loop,
  ScrollIntoView,
  GetRouteBasedValue,
  Capitalize,
  GetCustomField,
  SplitHtml,
  ScrollToAnchor,
  Debounce,
  LoadFacebookPixel,
  DarkTheme,
}
