<template>
  <div v-if="items.length" class="roles">
    <h2 class="roles__title">Megler</h2>

    <div class="roles__list">
      <div class="roles__item" v-for="(item, index) in items" :key="index">
        <div class="roles__item-top">
          <div class="roles__avatar" :style="{ backgroundImage: `url(${item.avatar})` }"></div>
          <h3 class="roles__name">{{ item.name }}</h3>
          <div
            class="roles__user-title"
            v-for="(title, index) in (item.title || '').split(',')"
            :key="index + 'title'"
          >
            {{ title }}
          </div>
        </div>
        <div class="roles__item-bottom">
          <a v-if="item.phone" class="roles__phone" :href="`tel:${item.phone}`">{{ item.phone }}</a>
          <a v-if="item.email" class="roles__email" :href="`mailto:${item.email}`">{{
            item.email
          }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    items() {
      return this.$path('data.content') || []
    },
  },
}
</script>

<style lang="scss">
.roles {
  color: black;
  a {
    color: black;
  }
  &__avatar {
    height: 380px;
    max-width: 320px;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
  }
  &__list {
    display: flex;

    flex-direction: column;
    gap: 3rem;
  }

  &__name {
    margin-bottom: 0.3rem;
  }

  &__title {
  }
  &__user-title {
    font-size: 0.75rem;
    text-transform: uppercase;
    color: $dark-grey;
  }

  &__item {
    display: flex;
    gap: 1rem;
    flex-direction: column;

    &-bottom {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

  &__email,
  &__phone {
    white-space: nowrap;
  }
}
</style>
