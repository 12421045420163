<template>
  <div class="kvass-image-text" v-if="value.length">
    <div
      v-for="(item, index) in value"
      :key="index"
      class="kvass-image-text__item"
      :class="`kvass-image-text__item-theme--${item.theme || 'none'}`"
    >
      <ImageComponent
        class="kvass-image-text__image"
        :value="{
          url: $path('image.url', item) || item.image,
          name: item.title,
          description: $path('image.description', item),
        }"
        v-bind="$attrs"
      />

      <div class="kvass-image-text__content">
        <div class="kvass-image-text__category">{{ item.category }}</div>
        <div class="kvass-image-text__description" v-html="item.description" />
      </div>
    </div>
  </div>
</template>

<script>
import ImageComponent from '@kvass/media-render/Types/Image'
export default {
  props: {
    data: Object,
  },
  computed: {
    value() {
      return this.$path('content', this.data) || []
    },
  },
  components: {
    ImageComponent,
  },
}
</script>

<style lang="scss">
.kvass-image-text {
  $breakAt: 850px;

  column-gap: 4rem;
  padding: 0;

  &__category {
    text-transform: uppercase;
    font-weight: 500;
    opacity: 0.8;
    font-size: 0.9rem;
  }

  &__item {
    $space: 20rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 7rem;
    align-items: center;
    margin: 0 !important;
    padding: 6rem 0rem;

    opacity: 0;

    @media (max-width: $breakAt) {
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      padding: 0 0 2rem 0;
    }

    @media (min-width: $breakAt + 1) {
      &:nth-child(odd) {
        direction: rtl;
        .kvass-image-text__content {
          text-align: right;
          justify-content: end;
        }
      }
    }

    @media (min-width: $breakAt + 1) {
      &:nth-child(even) {
        direction: initial !important;
        .kvass-image-text__content {
          text-align: right;
          justify-content: start;
        }
      }
    }

    * {
      direction: ltr;
      text-align: left;
    }

    &-theme {
      &--dark-green {
        background-color: var(--primary);
        color: var(--primary-contrast);
        padding: 6rem;
        @media (max-width: $breakAt) {
          padding: 0 0 2rem 0;
        }
      }
      &--beige {
        background-color: css-lighten('background-color', 10%);
        padding: 6rem;

        @media (max-width: $breakAt) {
          padding: 0 0 2rem 0;
        }
      }
    }
  }
  &__content {
    // margin: 0 1rem;
    // max-width: 440px;
    // width: 100%;
  }

  @media (max-width: $breakAt) {
    .kvass-image-text__content {
      margin: 1rem;
    }
  }
  @media (min-width: $breakAt) {
    .kvass-media-render-image {
      height: 560px;
    }
  }

  img {
    height: 100% !important;
  }
}
</style>
